<template>
  <div class="base-cover">
    <div class="Login base-height findemail">
      <div class="login_left"></div>
      <div class="mainBox">
        <h1>
          <router-link to="/">
            <img src="@/assets/images/reviewmind/logo2.png" alt="logo" />
          </router-link>
        </h1>
        <!-- <h2>로그인</h2> -->

        <div class="form login emailDone">
          <div class="emailInfo">
            <b>{{ email }}</b>
            회원님의 정보와 일치하는 이메일입니다.
          </div>
          <fieldset>
            <button type="button" class="btn full primary">
              <router-link to="/login"> 이메일로 로그인하기</router-link>
            </button>
            <button type="button" class="btn full secondary">
              <router-link to="/findPassword"> 비밀번호찾기</router-link>
            </button>
          </fieldset>
        </div>
      </div>
      <!-- <aside class="flexL">
        <router-link to="/login" class="btn">로그인</router-link>
      </aside> -->
    </div>
  </div>
</template>

<script>
import { format } from "@/mixins/format";
import { setFooter } from "@/mixins/setFooter";
import { setNavbar } from "@/mixins/setNavbar";

export default {
  mixins: [format, setNavbar, setFooter],
  data() {
    return {
      showSnackbar: false,
      visible: false,
      email: "",
      username: "",
      password: "",
      show: true,
      errMsg: false,
      snsLogin: true,
      mobileNo: "",
      certNumber: "",
      veyfyCodeVisible: false,
    };
  },
  mounted() {
    this.setNavbar(false);
    this.setFooter(false);
    if (this.$route.query.id) {
      this.email = this.$route.query.id;
    }
  },

  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  },
};
</script>
